<!-- <div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>Candidate List</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>Candidate List</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="profile-area profile-area-two pt-100">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="profile-item wow fadeInUp" data-wow-delay=".3s">
                    <img src="../../../../assets/dummy/dummy1.jpg" class="profile_images" alt="Profile">

                    <div class="profile-inner">
                        <h3>Jerry Hudson</h3>
                        <span>Trainer and Assessor</span>
                        <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                        <div class="profile-heart">
                            <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="profile-item wow fadeInUp" data-wow-delay=".4s">
                    <img src="../../../../assets/dummy/dummy2.jpg" class="profile_images" alt="Profile">

                    <div class="profile-inner">
                        <h3>Jac Jacson</h3>
                        <span>CEO</span>
                        <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                        <div class="profile-heart">
                            <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="profile-item wow fadeInUp" data-wow-delay=".5s">
                    <img src="../../../../assets/dummy/dummy3.jpg" class="profile_images" alt="Profile">

                    <div class="profile-inner">
                        <h3>Tom Potter</h3>
                        <span>Compliance Manager</span>
                        <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                        <div class="profile-heart">
                            <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="profile-item wow fadeInUp" data-wow-delay=".6s">
                    <img src="../../../../assets/dummy/dummy4.jpg" class="profile_images" alt="Profile">

                    <div class="profile-inner">
                        <h3>Shane Mac</h3>
                        <span>RTO Manager</span>
                        <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                        <div class="profile-heart">
                            <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="profile-item wow fadeInUp" data-wow-delay=".3s">
                    <img src="../../../../assets/dummy/dummy5.jpg" class="profile_images" alt="Profile">

                    <div class="profile-inner">
                        <h3>Liam Noah</h3>
                        <span>Training Coordinator</span>
                        <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                        <div class="profile-heart">
                            <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="profile-item wow fadeInUp" data-wow-delay=".4s">
                    <img src="../../../../assets/dummy/dummy6.jpg" class="profile_images" alt="Profile">

                    <div class="profile-inner">
                        <h3>William James</h3>
                        <span>Student Support Officer</span>
                        <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                        <div class="profile-heart">
                            <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="profile-item wow fadeInUp" data-wow-delay=".5s">
                    <img src="../../../../assets/dummy/dummy7.jpg" class="profile_images" alt="Profile">

                    <div class="profile-inner">
                        <h3>Lucas Mason</h3>
                        <span>Wall and Floor Tiler</span>
                        <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                        <div class="profile-heart">
                            <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="profile-item wow fadeInUp" data-wow-delay=".6s">
                    <img src="../../../../assets/dummy/dummy8.jpg" class="profile_images" alt="Profile">

                    <div class="profile-inner">
                        <h3>Daniel Henry</h3>
                        <span>Roof Tiler</span>
                        <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                        <div class="profile-heart">
                            <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="profile-item wow fadeInUp" data-wow-delay=".3s">
                    <img src="../../../../assets/dummy/dummy9.jpg" class="profile_images" alt="Profile">

                    <div class="profile-inner">
                        <h3>Jerry Hudson</h3>
                        <span>Automotive Technician</span>
                        <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                        <div class="profile-heart">
                            <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="profile-item wow fadeInUp" data-wow-delay=".3s">
                    <img src="../../../../assets/dummy/dummy10.jpg" class="profile_images" alt="Profile">

                    <div class="profile-inner">
                        <h3>Jerry Hudson</h3>
                        <span>Motor Mechanic</span>
                        <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                        <div class="profile-heart">
                            <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="profile-item wow fadeInUp" data-wow-delay=".3s">
                    <img src="../../../../assets/dummy/dummy11.jpg" class="profile_images" alt="Profile">

                    <div class="profile-inner">
                        <h3>Jerry Hudson</h3>
                        <span>RTO Manager</span>
                        <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                        <div class="profile-heart">
                            <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="profile-item wow fadeInUp" data-wow-delay=".3s">
                    <img src="../../../../assets/dummy/dummy12.jpg" class="profile_images" alt="Profile">

                    <div class="profile-inner">
                        <h3>Jerry Hudson</h3>
                        <span>Student Support Officer</span>
                        <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                        <div class="profile-heart">
                            <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<div class="banner-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-text text">
                    <h1>Candidate <span>List</span></h1>
                    <p>Jobs, Employment & Future Career Opportunities</p>

                    <div class="banner-form-area">
                        <form>
                            <div class="row">
                                <!-- <div class="col-md-4">
                                    <div class="form-group">
                                        <label
                                            ><i class="fas fa-building"></i
                                        ></label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Search Name"
                                               [(ngModel)]="name"
                                            [ngModelOptions]="{standalone: true}"
                                        />
                                    </div>
                                </div> -->
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label
                                            ><i class="fas fa-tags"></i
                                        ></label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Search Category"
                                               [(ngModel)]="category"
                                            [ngModelOptions]="{standalone: true}"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label
                                            ><i class="fas fa-globe"></i
                                        ></label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Search Country"
                                               [(ngModel)]="country"
                                            [ngModelOptions]="{standalone: true}"
                                        />
                                    </div>
                                </div>
                            </div>

                            <button
                                type="submit"
                                (click)="getAllCandidateList()"
                                class="btn banner-form-btn"
                            >
                                Search
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container back-link">
    <a routerLink="/home" class="cursor_pointer h5"><i class="fa fa-angle-double-left me-2"></i>Back</a>
</div>


<section class="job-area job-area-two pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6" *ngFor="let data of candidateList ; let i = index">
                <div class="job-item">
                    <div class="job-inner align-items-center">
                        <div class="job-inner-left">
                            <h3>
                                Candidate {{i+1}}
                            </h3>
                            <ul class="mt-3">
                                <li>
                                    <i class="fas fa-building"></i
                                    >{{ data?.category }}
                                </li>
                            </ul>

                            <ul class="mt-3">
                                <b>Experience</b
                                >:
                                <div
                                    *ngIf="data?.yearsOfExperience"
                                    class="mt-2"
                                >
                                    <span>
                                        <li
                                            *ngFor="
                                                let experience of data.yearsOfExperience
                                            "
                                        >
                                            <div
                                                class="d-flex justify-content-between w-100"
                                            >
                                                <div>
                                                    <h6>
                                                        {{
                                                            experience.jobTitle
                                                        }}
                                                    </h6>
                                                    <p>
                                                        {{
                                                            experience.companyName
                                                        }}
                                                    </p>
                                                </div>
                                                <div>
                                                    <ng-container
                                                        *ngIf="
                                                            experience?.endedMonth &&
                                                                experience?.endedYear;
                                                            else presentTemplate
                                                        "
                                                    >
                                                        <h6>
                                                            ({{
                                                                experience?.startedYear
                                                            }}) - ({{
                                                                experience?.endedYear
                                                            }})
                                                        </h6>
                                                    </ng-container>

                                                    <ng-template
                                                        #presentTemplate
                                                    >
                                                        <div>
                                                            <h6>
                                                                ({{
                                                                    experience?.startedYear
                                                                }}) - present
                                                            </h6>
                                                        </div>
                                                    </ng-template>
                                                </div>
                                            </div>
                                        </li>
                                    </span>
                                </div>
                            </ul>

                            <div *ngIf="data.skills?.length">
                                <ul class="mt-3">
                                    <span
                                        ><b>Skills</b> :
                                        {{ data.skills.join(", ") }}</span
                                    >
                                </ul>
                                <!-- <div *ngFor="let skills of data.skills">
                                
                        </div> -->
                            </div>
                            <a
                                class="mt-3"
                                [routerLink]="['/candidate-details',data?._id , 'Candidate-' + (i + 1)]"
                                >View Profile<i class="icofont-swoosh-right"></i
                            ></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <nav aria-label="Page navigation example pagination" class="pagination-nav">
        <ul class="pagination">
            <li class="page-item" [class.disabled]="currentPage === 1">
                <a
                    class="page-link cursor_pointer"
                    (click)="onPageChange(currentPage - 1)"
                    aria-label="Previous"
                >
                    <span aria-hidden="true">&laquo;</span>
                </a>
            </li>
            <li
                class="page-item"
                *ngFor="let page of [].constructor(totalPages); let i = index"
            >
                <a
                    class="page-link cursor_pointer"
                    (click)="onPageChange(i + 1)"
                    >{{ i + 1 }}</a
                >
            </li>
            <li class="page-item" [class.disabled]="currentPage === totalPages">
                <a
                    class="page-link cursor_pointer"
                    (click)="onPageChange(currentPage + 1)"
                    aria-label="Next"
                >
                    <span aria-hidden="true">&raquo;</span>
                </a>
            </li>
        </ul>
    </nav>

    <div class="pagination-controls pagination">
        <span>Page {{ currentPage }} of {{ totalPages }}</span>
    </div>
</section>

<!-- Modal -->
<div
    class="modal fade right-modal"
    id="rightSideModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
>
    <div class="modal-dialog modal-dialog-right">
        <div class="modal-content">
            <div class="modal-header">
                <!-- <h2 class="modal-title" id="exampleModalLabel">Recent Experience</h2> -->
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <div class="modal-body">
                <app-candidate-details> </app-candidate-details>
            </div>
        </div>
    </div>
</div>
