import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { AuthService } from "src/app/store/auth/auth.service";
declare var Calendly: any;
import { UserRole } from "src/app/shared/enums/roles";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
    selector: "app-navbar",
    templateUrl: "./navbar.component.html",
    styleUrls: ["./navbar.component.scss"],
})
// routerLink="/admin/job-create"
// routerLink="/employer/employer-profile"
// routerLink="/candidate/candidate-profile"

export class NavbarComponent implements OnInit {
    loginAuthO = this.authService.userO;
    registerUser = this.authService.userO;
    private loginSub: Subscription;
    private registerSub: Subscription;
    user: boolean = false;
    updateLoginUser: boolean;
    userInfo: any = {};
    isLargeScreen: boolean = false;
    
    constructor(
        private readonly authService: AuthService,
        private readonly router: Router
    ) {
    }

    
    ngOnInit():void {
        // Expose the component instance globally
        (window as any).NavbarComponentInstance = this;
      }

    ngAfterViewInit(){
        setTimeout(() => {
            this.loginSub = this.loginAuthO.subscribe((data) => {
                if(!data) return
                if(data?.isData === true){
                    this.user = true
                   this.userInfo = data?.body;
                }
            });
          },0);
    }

    handleLogout() {
        this.user = false;
        this.userInfo = ''
        localStorage.removeItem("token");
        localStorage.removeItem("user-data");
        localStorage.removeItem("qualification");
        localStorage.removeItem("certificates");
        localStorage.removeItem("experience");
        this.authService.setNullUser();
        this.router.navigate(["/management-module/login"]);
    }

    isUserLoggedIn() {}
    // openCalendly(): void {
    //     if (this.userInfo?.role === 'employee') {
    //     // Use Calendly's popup widget to open the scheduling interface
    //     Calendly.initPopupWidget({
    //         url: "https://calendly.com/accounts-magnusrecruitment/30min", // Replace with your scheduling link
    //     });
    // }
    // }

    // updated code
    openCalendly(): void {
        // Use Calendly's popup widget to open the scheduling interface
        Calendly.initPopupWidget({
            url: "https://calendly.com/accounts-magnusrecruitment/30min", // Replace with your scheduling link
        });
    }

    ngOnDestroy() {
        this.loginSub.unsubscribe();
        this.registerSub.unsubscribe();
    }
}
