import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CandidateEmployerListService {


  constructor(private readonly httpClient: HttpClient) { }

 
  //get all candidate list
  getAllCandidateList(
    currentPage: number,
    totalItemPerPage: number,
    name:string = '',
    email:string = '',
    category:string = '',
    country:string = '',
  ) {

// remove emty spaces 
  name = name.trim();
  email = email.trim();
  category = category.trim();
  country = country.trim();

    return this.httpClient.get(
      `${environment.baseUrl}/candidate/get-all-candidates/${currentPage}/${totalItemPerPage}?name=${name}&email=${email}&category=${category}
      &country=${country}`
    );
  }


  //get all employers list
  getAllEmployerList(
    currentPage: number,
    totalItemPerPage: number,
    collegeOrEmail:string = ""
  ){
    return this.httpClient.get(
         `${environment.baseUrl}/employer/get-all-employers/${currentPage}/${totalItemPerPage}?collegeOrEmail=${collegeOrEmail}`
  );
  }


  //get specific candidate by id
  getSpecificCandidateById(id:any) {
   return this.httpClient.get(`${environment.baseUrl}/candidate/get-specific-candidate/${id}`)
  }

  //assign email to employer
  assignEmailToEmployer(id: any, email:any) {
    return this.httpClient.patch(`${environment.baseUrl}/candidate/update-candidate-key?candidateId=${id}&email=${email}`,{})
  }

  //get all candidates assigned to employer
  getAllCandidatesAssignedToEmployer(currentPage: number,
    totalItemPerPage: number, name: string , email: string , candidateEmail: string) {
    return this.httpClient.get(`${environment.baseUrl}/candidate/get-candidates-for-employee/${currentPage}/${totalItemPerPage}?name=${name}&email=${email}&candidateEmail=${candidateEmail}`)
  }

  //get all employers
  getAllEmployersEmail() {
    return this.httpClient.get(`${environment.baseUrl}/employer/get-employers-email`)
  }

  //get all assigned candidates
  getAllAssignedCandidates(currentPage: number,
    totalItemPerPage: number, name: string , email:string , employerEmail:string){
    return this.httpClient.get(`${environment.baseUrl}/candidate/fetch-all-assign-candidates/${currentPage}/${totalItemPerPage}?name=${name}&email=${email}&employerEmail=${employerEmail}`)
  }

  //unAssigned candidate to employer
  unAssignEmailToEmployer(email:any) {
    return this.httpClient.patch(`${environment.baseUrl}/candidate/unassign-candidate-from-employee?email=${email}`,{})
  }

  // terminate Candidate with it's id
  deleteSpecificCandidateById(id:any) {
    return this.httpClient.delete(`${environment.baseUrl}/candidate/delete-specific-candidate/${id}`)
   }

  // terminate Candidate with it's id
  deleteSpecificEmployeerById(id:any) {
    return this.httpClient.delete(`${environment.baseUrl}/employer/delete-specific-employer/${id}`)
   }
  
}
