import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CandidateEmployerListService } from 'src/app/shared/services/candidate-employer-list.service';

@Component({
  selector: 'app-candidate-details',
  templateUrl: './candidate-details.component.html',
  styleUrls: ['./candidate-details.component.scss']
})
export class CandidateDetailsComponent implements OnInit {

  candidateId: any;
  candidateNo:any
  candidateInfo: any = {};
  // @Input() Id: any;
  
  constructor(
      private readonly activeRoute: ActivatedRoute,
      private readonly candidateListService: CandidateEmployerListService
  ) {}

  ngOnInit() {
      this.candidateId = this.activeRoute.snapshot.paramMap.get("id");
      this.candidateNo = this.activeRoute.snapshot.paramMap.get("candidate")?.replace('-', ' '); 
      this.getSpecificCandidateDetails()
  }

  getSpecificCandidateDetails() {
      this.candidateListService
          .getSpecificCandidateById(this.candidateId)
          .subscribe((res: any) => {
            this.candidateInfo = res?.body?.findUniqueCandidateInfo
          });
  }

}
