import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-one',
  templateUrl: './home-one.component.html',
  styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {
  category: string = '';
  categoryArray:any[] = []
  job:string = '';

  videos = [
    { title: 'Magnus Recruitment...', url: '../../../../assets/img/home-1/magnus.mp4', description: 'Weekly Job Newsletter' },
    { title: 'Magnus Recruitment...', url: '../../../../assets/img/home-1/magnus.mp4', description: 'Weekly Job Newsletter' },
    { title: 'Magnus Recruitment...', url: '../../../../assets/img/home-1/magnus.mp4', description: 'Weekly Job Newsletter' },
    { title: 'Magnus Recruitment...', url: '../../../../assets/img/home-1/magnus.mp4', description: 'Weekly Job Newsletter' }
  ];
  constructor(private router: Router) { }

  ngOnInit(): void {
    localStorage.removeItem('questions-id')
  }



  navigateToCandidateList() {
    this.router.navigate(['/candidate-list'], {
        queryParams: {
            category: this.category,
        }
    });

}

navigateToJobList(){
  this.router.navigate(['/job-list'], {
    queryParams: {
        job: this.job,
    }
});

// const jobArray = [this.job];
// this.router.navigate(['/job-list'], {
//     queryParams: {
//         job: JSON.stringify(jobArray) // Pass as JSON string
//     }
// });
}

}
