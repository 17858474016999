import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CandidateEmployerListService } from 'src/app/shared/services/candidate-employer-list.service';
import { JobCreateService } from 'src/app/shared/services/job-create.service';

@Component({
    selector: 'app-candidate-list',
    templateUrl: './candidate-list.component.html',
    styleUrls: ['./candidate-list.component.scss']
})
export class CandidateListComponent implements OnInit {
    candidateList: any[] = [];
    candidateId: number;
    dataNotFound: string = "Candidate's Not Found";
    currentPage: number = 1;
    totalItemsPerPage = 10;
    totalPages: number = 0;
    totalItems: number = 0;
    firstName: string = "";
    middleName: string = "";
    lastName: string = "";
    email: string = "";
    phoneNumber: string = "";
    employerEmail: FormGroup | any;
    qualification: any[] = [];
    selectedCandidateId: any;
    jobCategory: any[] = [];
    category: string = '';
    name: string = '';
    country: string = '';



    constructor(
        private readonly candidateListService: CandidateEmployerListService,
        private readonly fb: FormBuilder,
        private readonly ngxToaster: ToastrService,
        private route: ActivatedRoute,
        private readonly jobCreateService: JobCreateService
    ) { }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            this.category = params['category']
        });
        this.initEmployerEmail();
        this.getAllCandidateList();

    }

    initEmployerEmail() {
        this.employerEmail = this.fb.group({
            email: ["", [Validators.required, Validators.email]],
        });
    }



    //get all candidate list
    getAllCandidateList() {
        console.log("Name", this.name)
        this.candidateListService
            .getAllCandidateList(
                this.currentPage,
                this.totalItemsPerPage,
                this.name,
                this.email,
                this.category,
                this.country
            )
            .subscribe((res: any) => {
                this.candidateList = res?.body;
                console.log(this.candidateList)
                // this.candidateList.forEach((candidate) => {
                //   if (candidate?.qualifications) {
                //     this.qualification.push(...candidate.qualifications);
                //   }
                // });
                this.totalItems = res?.totalRecords;
                this.totalPages = Math.ceil(
                    this.totalItems / this.totalItemsPerPage
                );
            });
    }

    assign(id: any) {
        this.candidateId = id;
    }

    openModel(id: string) {
        this.selectedCandidateId = id;
    }

    onPageChange(page: number) {
        if (page >= 1 && page <= this.totalPages) {
            this.currentPage = page;
            this.getAllCandidateList();
        }
    }

    submit() {
        if (!this.employerEmail.valid) {
            this.touchFormControls();
            return;
        }
        const formValue = this.employerEmail.value;
        this.candidateListService
            .assignEmailToEmployer(this.candidateId, formValue.email)
            .subscribe((res: any) => {
                if (res?.isData === true) {
                    this.ngxToaster.success(res?.result);
                    const closeButton = document.querySelector('[data-bs-dismiss="modal"]') as HTMLElement;
                    closeButton?.click();
                    this.employerEmail.reset()

                } else if (res?.isData === false) {
                    this.ngxToaster.error(res?.result);
                }
            });
    }


    touchFormControls() {
        Object.keys(this.employerEmail.controls).forEach((key) => {
            this.employerEmail.get(key)?.markAsTouched();
        });
    }


    //ngx-selector-dropDown-Configuration for job category
    config = {
        displayKey: "category",
        search: true,
        height: "auto",
        placeholder: "Job Category",
        customComparator: () => { },
        moreText: "more",
        noResultsFound: "No results found!",
        searchPlaceholder: "Search",
        searchOnKey: "category",
        clearOnSelection: false,
        inputDirection: "ltr",
        multiple: true,
    };
}
