<div class="banner-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-text text">
                    <h1>Find Your <span>Desire</span> Job</h1>
                    <p>Jobs, Employment & Future Career Opportunities</p>

                    <div class="banner-form-area">
                        <form>
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label
                                            ><i class="icofont-search-1"></i
                                        ></label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Job Title"
                                            [ngModelOptions]="{
                                                standalone: true
                                            }"
                                            [(ngModel)]="jobTitle"
                                        />
                                    </div>
                                </div>

                                <!-- <div class="col-lg-4">
                                    <div class="form-group">
                                        <label
                                            ><i class="icofont-location-pin"></i
                                        ></label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="City or State"
                                            [(ngModel)]="jobLocation"
                                            [ngModelOptions]="{
                                                standalone: true
                                            }"
                                        />
                                    </div>
                                </div> -->
                                <!-- <div class="col-lg-4">
                                    <div class="form-group">
                                        <label
                                            ><i class="icofont-location-pin"></i
                                        ></label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="City or State"
                                            [(ngModel)]="searchQuery"
                                            (ngModelChange)="onSearch($event)"
                                            [ngModelOptions]="{
                                                standalone: true
                                            }"
                                        />
                                    </div> -->

                                    <!-- Dropdown for showing states -->
                                    <!-- <ul
                                        *ngIf="filteredStates.length > 0"
                                        class="dropdown-menu show"
                                    >
                                        <li
                                            *ngFor="let state of filteredStates"
                                            (click)="selectState(state)"
                                        >
                                            {{ state.name }}
                                        </li>
                                    </ul>
                                </div> -->


                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label
                                            ><i class="icofont-location-pin"></i
                                        ></label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="State"
                                            [(ngModel)]="searchQuery"
                                            (ngModelChange)="onSearchCity($event)"
                                            [ngModelOptions]="{
                                                standalone: true
                                            }"
                                        />
                                    </div>

                                    <!-- Dropdown for showing states -->
                                    <ul
                                        *ngIf="filteredStates.length > 0"
                                        class="dropdown-menu show"
                                    >
                                        <li
                                            *ngFor="let state of filteredStates"
                                            (click)="selectState(state)"
                                        >
                                            {{ state.name }}
                                        </li>
                                    </ul>


                                    <div class="form-group" *ngIf="selectedState">
                                        <label class="d-block"
                                            ><i class="icofont-location-pin"></i></label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="City "
                                            [(ngModel)]="searchQueryCity"
                                            (ngModelChange)="onSearchCity($event)"
                                            [ngModelOptions]="{
                                                standalone: true
                                            }"
                                        />
                                    </div>

                                    <!-- Dropdown for showing states -->
                                    <ul
                                        *ngIf="filteredStates.length > 0"
                                        class="dropdown-menu show"
                                    >
                                        <li
                                            *ngFor="let city of filteredStates"
                                            (click)="selectState(city)"
                                        >
                                            {{ city.name }}
                                        </li>
                                    </ul>
                                </div>

                                <div class="col-lg-4">
                                    <div class="form-group mt-2">
                                        <ngx-select-dropdown
                                            [config]="config"
                                            [options]="jobCategory"
                                            [multiple]="true"
                                            (change)="onSelectCategory($event)"
                                        ></ngx-select-dropdown>
                                    </div>
                                </div>

                                <!-- <div class="col-lg-4">
                                    <div class="form-group">
                                        <label
                                            ><i class="fa fa-briefcase"></i
                                        ></label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="2 years"
                                            [(ngModel)]="jobExperience"
                                            [ngModelOptions]="{
                                                standalone: true
                                            }"
                                        />
                                    </div>
                                </div> -->

                                <!-- <div class="col-lg-4">
                                    <div class="form-group mt-2">
                                        <label><i class="fa fa-usd"></i></label>
                                        <ngx-select-dropdown
                                            [config]="jobSalaryConfig"
                                            [options]="jobSalary"
                                            (change)="
                                                onSelectSalaryRange($event)
                                            "
                                        >
                                        </ngx-select-dropdown>
                                
                                    </div>
                                </div> -->

                                <div class="col-lg-4">
                                    <div class="form-group mt-2">
                                        <label>Job Type</label>
                                        <ngx-select-dropdown
                                            [config]="jobTypeConfig"
                                            [options]="jobTypes"
                                            (change)="onSelectJobType($event)"
                                        >
                                        </ngx-select-dropdown>
                                    </div>
                                </div>
                            </div>

                            <button
                                type="submit"
                                (click)="jobInfoListing()"
                                class="btn banner-form-btn mt-3 me-2"
                            >
                                Search
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container back-link">
    <a routerLink="/home" class="cursor_pointer h5"><i class="fa fa-angle-double-left me-2"></i>Back</a>
</div>
<section class="job-area job-area-two pt-100">
    <div class="container">

        <div class="row mt-4">
            <div class="col-lg-6" *ngFor="let job of jobInfo">
                <div class="job-item">
                    <!-- <img
                        [src]="job?.companyLogoDetails?.imageUrl"
                        class="job_image"
                        alt="Job"
                    /> -->
                    <img src="../../../assets/img/home-1/icon.jpeg"  class="job_image" alt="">
                    <div class="job-inner align-items-center">
                        <div class="job-inner-left">
                            <h3>{{ job?.jobTitle }}</h3>

                            <!-- <a [href]="job?.website" target="_blank">{{
                                job?.website
                            }}</a> -->
                            <ul>
                                <li>
                                    <i class="icofont-location-pin"></i
                                    >{{ job?.jobLocation }}
                                </li>
                                <li>
                                    <i class="far fa-building"></i
                                    >{{ job?.jobCategory }} ({{
                                        job?.jobTitle
                                    }})
                                </li>
                                <!-- <li>
                                    <i class="fa fa-clock-o"></i
                                    >{{ job?.jobType }}
                                </li> -->
                                <ng-container>
                                    <div
                                        *ngIf="
                                            job.jobType === 'Hourly';
                                            else displayWithK
                                        "
                                    >
                                        <li>
                                            <i class="fa fa-money"></i>
                                            ${{ job?.salaryRange }}
                                        </li>
                                    </div>
                                    <ng-template #displayWithK>
                                        <li>
                                            <i class="fa fa-money"></i>
                                            {{ job?.salaryRange }}k
                                        </li>
                                    </ng-template>
                                </ng-container>
                                <!-- <li>
                                    <i class="fa fa-money"></i
                                    >{{ job?.salaryRange }}
                                </li> -->
                            </ul>
                            <ul class="mt-3">
                                <span class="fw-bold">Job Requirement:</span>
                                <li
                                    *ngFor="
                                        let requirement of job.jobRequirementsArray;
                                        let i = index
                                    "
                                >
                                    {{ requirement }}
                                </li>
                            </ul>

                            <ul class="mt-3">
                                <span class="fw-bold">Job Description:</span>
                                <li>
                                    {{ job?.jobDescription }}
                                </li>
                            </ul>
                        </div>
                        <div class="job-inner-right">
                            <ul>
                                <li>
                                    <a
                                        [routerLink]="[
                                            '/candidate/job-detail',
                                            job?._id
                                        ]"
                                        >Apply</a
                                    >
                                </li>

                                <!-- <li>
                                    <span>{{ job?.jobType }}</span>
                                </li> -->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="jobInfo === null" class="text-center mb-5">
                <h5>{{ this.dataNotFound }}</h5>
            </div>

            <nav aria-label="Page navigation example pagination">
                <ul class="pagination">
                    <li class="page-item" [class.disabled]="currentPage === 1">
                        <a
                            class="page-link cursor_pointer"
                            (click)="onPageChange(currentPage - 1)"
                            aria-label="Previous"
                        >
                            <span aria-hidden="true">&laquo;</span>
                        </a>
                    </li>
                    <li
                        class="page-item"
                        *ngFor="
                            let page of [].constructor(totalPages);
                            let i = index
                        "
                    >
                        <a
                            class="page-link cursor_pointer"
                            (click)="onPageChange(i + 1)"
                            >{{ i + 1 }}</a
                        >
                    </li>
                    <li
                        class="page-item"
                        [class.disabled]="currentPage === totalPages"
                    >
                        <a
                            class="page-link cursor_pointer"
                            (click)="onPageChange(currentPage + 1)"
                            aria-label="Next"
                        >
                            <span aria-hidden="true">&raquo;</span>
                        </a>
                    </li>
                </ul>
            </nav>

            <div class="pagination-controls pagination">
                <span>Page {{ currentPage }} of {{ totalPages }}</span>
            </div>
        </div>
    </div>
</section>
